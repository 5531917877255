import React, { useEffect, useState } from "react";
import theme from "../../theme";
import { fetchOrderDetails } from "../api/useFetchProducts";
import { useNavigate } from "react-router-dom";

export default function OrderHistory() {
  const [orderList, setOrderList] = useState([]);
  const [range, setRange] = useState(3); // Default to 3 months
  const navigate = useNavigate();

  useEffect(() => {
    const loadOrderDetails = async () => {
      await fetchOrderDetails(); // Call the function to fetch order details
      const storedOrders = JSON.parse(localStorage.getItem("listOrder")); // Retrieve orders from local storage
      setOrderList(storedOrders || []); // Set the orders state
    };

    loadOrderDetails(); // Call the function
  }, []);

  const handleRangeChange = (event) => setRange(event.target.value);
  console.log("orderList", orderList);

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center py-2">
        <h5>Order History</h5>
        {/* <select
          value={range}
          onChange={handleRangeChange}
          className="border-none form-select custom-select"
          style={{ backgroundColor: theme.palette.indigo.light }}
        >
          <option value={1}>1 Month</option>
          <option value={3}>3 Months</option>
          <option value={6}>6 Months</option>
          <option value={12}>1 Year</option>
        </select> */}
      </div>
      {orderList.map((order) => (
        <div
          key={order._id}
          className="p-2 mb-2 rounded-3 pointer"
          style={{ backgroundColor: theme.palette.indigo.light }}
          onClick={() => navigate(`/orderStatus/${order._id}`)}
        >
          <div className="d-flex justify-content-between">
            <div className="fw-semibold" style={{ fontSize: "15px" }}>
              {order.cart_data[0]?.title || "No title available"}
            </div>
            <div
              className="fw-semibold"
              style={{
                fontSize: "13px",
                color:
                  order.status === "ACCEPTED"
                    ? "#4caf50"
                    : order.status === "CREATED"
                    ? "#fbc02d"
                    : order.status === "COMPLETED"
                    ? "#1976d2"
                    : "#e57373",
              }}
            >
              {order.status === "ACCEPTED"
                ? "Accepted"
                : order.status === "CREATED"
                ? "Placed"
                : order.status === "COMPLETED"
                ? "Deliverd"
                : "Canceled"}
            </div>
          </div>
          <div
            className="d-flex justify-content-between"
            style={{ fontSize: "14px" }}
          >
            <div className="roboto ">
              Total:{" "}
              <span
                className="fw-semibold"
                style={{ color: theme.palette.indigo.dark }}
              >
                ₹{order.grandtotal}
              </span>
            </div>
            <div className="fw-semibold">+{order.cart_data.length} Items</div>
          </div>
        </div>
      ))}
    </div>
  );
}
