import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getAccessToken } from "../Utiles/CookieUtiles";
import Cookies from "js-cookie";


export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  
  if (refreshToken) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}create-shop-token`,
        { refreshToken }
      );
      
      const newAccessToken = response.data.results.accessToken;
      localStorage.setItem("accessToken", newAccessToken);
      return newAccessToken;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  }
  return null;
};

export const useFetchProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const role=localStorage.getItem("userRole");
  console.log("role",role)

  useEffect(() => {
    const fetchProducts = async () => {
      let token = localStorage.getItem("accessToken");

      const fetchData = async () => {
        try {
          let response;
          if(role==='AGENT'){
           response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}list-user-products`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          }else{
             response = await axios.get(
              `${process.env.REACT_APP_BASE_URL}list-products`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }

          if (response.data.statuscode === 201) {
            setProducts(response.data.results);
          } else if (response.data.statuscode === 401) {
            throw new Error("Unauthorized");
          }
        } catch (error) {
          if (error.message === "Unauthorized") {
            // Attempt to refresh the token if the response is 401
            token = await refreshAccessToken();
            if (token) {
              // Retry fetching products with the new token
              fetchData();
            } else {
              localStorage.removeItem("accessToken");
              localStorage.removeItem('userRole')
              localStorage.removeItem("listOrder");
              navigate("/login");
            }
          } else {
            console.error("Error fetching products:", error);
            setError("Failed to fetch products. Please try again later.");
            localStorage.removeItem("accessToken");
            localStorage.removeItem('userRole')
            localStorage.removeItem("listOrder");
            navigate("/login");
          }
        } finally {
          setLoading(false);
        }
      };

      if (token) {
        fetchData();
      } else {
        localStorage.removeItem("accessToken");
        localStorage.removeItem('userRole')
        localStorage.removeItem("listOrder");
        navigate("/login");
      }
    };

    fetchProducts();
  }, [navigate]);

  return { products, loading, error };
};



export const fetchShopDetails = async () => {

    const token = localStorage.getItem("accessToken");
    const role =localStorage.getItem('userRole');
    console.log('shopdapi',token)

  if (!token) {
    throw new Error("No access token found. Please log in.");
  }

  try {
    let response;
    if(role==='AGENT'){
     response = await axios.post(`${process.env.REACT_APP_BASE_URL}list-user-shop`, 
     {},
     {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })}
    else{
       response = await axios.get(`${process.env.REACT_APP_BASE_URL}view-shop`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    }
    Cookies.set("shopData", JSON.stringify(response.data.results))
    localStorage.setItem('shopData', JSON.stringify(response.data.results))
    return response.data; // Return the shop details
  } catch (error) {
    console.error("Error fetching shop details:", error);
    throw new Error("Failed to fetch shop details.");
  }
};


export const checkOrder = async (productids) => {
    const token = localStorage.getItem("accessToken");
    const role =localStorage.getItem("userRole")

    try {
      let response;
      if(role==="AGENT"){
         response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}check-user-order`,
            { productids },
            { headers: { Authorization: `Bearer ${token}` } }
        )}else{
          response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}check-order`,
            { productids },
            { headers: { Authorization: `Bearer ${token}` } }
        )
        }
        return response.data;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
};

export const fetchOrderDetails = async () => {
  const token = localStorage.getItem("accessToken");
  const role= localStorage.getItem("userRole")
  if (!token) return;

  try {
    let response;
    if(role==="AGENT"){
     response = await axios.get(`${process.env.REACT_APP_BASE_URL}list-user-order`, {
      headers: { Authorization: `Bearer ${token}` },
    })}
    else{
      response = await axios.get(`${process.env.REACT_APP_BASE_URL}list-order`, {
        headers: { Authorization: `Bearer ${token}` },
      })
    }
    
    const orders = response.data.results; // Get all orders
    localStorage.setItem("listOrder", JSON.stringify(orders)); // Store all orders in local storage
  } catch (error) {
    console.error("Error fetching orders:", error);
  }
};

export const fetchOrderData = async (orderId, token) => {
  const role=localStorage.getItem("userRole");
  try {
    let response;
    if(role==="AGENT"){
     response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}view-user-order`,
      { orderid: orderId },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Add Authorization header if needed
          'Content-Type': 'application/json', 
        },
      }
    )
    }else{
      response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}view-order`,
        { orderid: orderId },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add Authorization header if needed
            'Content-Type': 'application/json', 
          },
        }
      )
    }
    return response.data;
  
  } catch (error) {
    console.error("Failed to fetch order data:", error);
    throw error;
  }
};