import Cookies from "js-cookie";

export const getAccessToken= ()=>{
   return Cookies.get('allagappa_accessToken');
}

export const getRefreshToken=()=>{
    return Cookies.get('allagappa_refreshToken');
}

export const setAccessToken = (token) => {
    //  const expirationTime = new Date(new Date().getTime() + 1 * 60 * 1000);
    Cookies.set('allagappa_accessToken', token, {
      secure: true,
      sameSite: 'Strict',
      path: '/'
    });
  };
  
  export const setRefreshToken = (token) => {
    Cookies.set('allagappa_refreshToken', token, {
      expires: 300, // 300 days
      secure: true,
      sameSite: 'Strict',
      path: '/'
    });
  };

  export const removeAccessToken = () => {
    Cookies.remove('allagappa_accessToken', { path: '/' });
  };
  
  export const removeRefreshToken = () => {
    Cookies.remove('allagappa_refreshToken', { path: '/' });
  };
  