import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { addItemToCart, setCartlist } from "../../redux/slices/CartSlice";
import theme from "../../theme";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function ProductDetailsPopup({ product, onClose, index }) {
  const dispatch = useDispatch();
  const cartitem = useSelector((state) => state.cartlists.cartItems);
  const [open, setOpen] = useState(false);

  const existingCartItem = cartitem.find((item) => item._id === product._id);
  const [tempQuantity, setTempQuantity] = useState(existingCartItem ? existingCartItem.quantity : 1);

  useEffect(() => {
    if (existingCartItem) {
      setTempQuantity(existingCartItem.quantity);
    }
  }, [existingCartItem]);

  const handleTempQuantityChange = (value) => {
    setTempQuantity(value === "" ? "" : Math.max(1, parseInt(value, 10) || 1));
  };

  const handleIncreaseQuantity = () => {
    setTempQuantity((prev) => (prev === "" ? 1 : prev + 1));
  };

  const handleDecreaseQuantity = () => {
    setTempQuantity((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const handleAddToCart = () => {
    const updatedCartItems = cartitem.map((item) =>
      item._id === product._id ? { ...item, quantity: tempQuantity - 1 } : item
    );

    if (!existingCartItem) {
      updatedCartItems.push({ ...product, quantity: tempQuantity - 1 });
    }

    dispatch(setCartlist(updatedCartItems));
    dispatch(addItemToCart(product));
    setOpen(true);
    onClose();
    // setTimeout(() => onClose());
  };

  const handlsnackbar=()=>{
    setOpen(true);
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  return (
    <>
      <div
        className="popup-overlay"
        onClick={onClose} // Close popup on clicking outside
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="popup-content p-4 bg-white rounded"
          onClick={(e) => e.stopPropagation()} // Prevent closing on clicking inside
          style={{ maxWidth: "400px", width: "100%" }}
        >
          <span className="fw-semibold title" style={{ fontSize: "16px" }}>
            {product.title}
          </span>

          <div className="d-flex justify-content-between align-items-center mt-1 pb-2">
            <span className="roboto" style={{ fontSize: "13px", color: "#9e9e9e" }}>
              <span>{tempQuantity || 1} × ₹{product.saleprice}</span>
            </span>
            <span className="fw-semibold roboto" style={{ fontSize: "16px" }}>
              ₹{((tempQuantity || 1) * product.saleprice).toFixed(2)}
            </span>
          </div>

          <div className="d-flex justify-content-end align-items-center mt-2">
            <button
              className="btn btn-sm btn-outline-secondary rounded-3 p-0"
              onClick={handleDecreaseQuantity}
              style={{ width: "25px", height: "25px" }}
            >
              <FontAwesomeIcon icon={faMinus} />
            </button>

            <input
              type="number"
              className="text-center fw-semibold mx-1"
              value={tempQuantity}
              onChange={(e) => handleTempQuantityChange(e.target.value)}
              onBlur={() => {
                if (tempQuantity === "") {
                  setTempQuantity(1);
                }
              }}
              style={{
                width: "60px",
                height: "25px",
                lineHeight: "25px",
                border: "none",
                backgroundColor: "#f5f5f5",
                borderRadius: "4px",
                textAlign: "center",
              }}
              min="1"
            />

            <button
              className="btn btn-sm btn-outline-secondary rounded-3 p-0"
              onClick={handleIncreaseQuantity}
              style={{ width: "25px", height: "25px" }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>

          <div className="d-flex justify-content-between mt-3">
            <button className="btn me-2 cancelbtnhover" onClick={onClose} style={{ backgroundColor: theme.palette.indigo.light, color: '#616161' }}>
              <FontAwesomeIcon icon={faXmark} className="me-2" />Cancel
            </button>
            <button className="btn popaddbtnhover" style={{ backgroundColor: theme.palette.indigo.main, color: 'white' }} onClick={handleAddToCart}>
              <FontAwesomeIcon icon={faCheck} className="me-2" />Add to Cart
            </button>
          </div>
        </div>
      </div>
     
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '30%' }}>
          Item added to cart successfully!
        </Alert>
      </Snackbar>
    </>
  );
}

