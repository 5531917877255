import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ProductDetailsPopup from './ProductPopup';
import theme from '../../theme';

export default function ProductTemp({ product,index }) {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleAddCart = () => {
    setSelectedProduct(product);
    setShowPopup(true);
  };

  const closePopup = () => setShowPopup(false);

  return (
    <>
      <div key={product.id} className="col-md-12 mb-1">
        <div className="d-flex justify-content-between align-items-start p-3 mt-1 bg-white rounded-3">
          <div className="flex-grow-1">
            <span
              className="fw-semibold text-break d-block title"
              style={{
                fontSize: '15px',
                wordWrap: 'break-word',
                whiteSpace: 'normal',
              }}
            >
              {product.title}({product.code})
            </span>
            <p className="mb-0 price" style={{ fontSize: '14px' }}>
              <span className="text-dark me-2 ">
                Saleprice: <span className="fw-semibold" style={{color:theme.palette.indigo.dark}}>₹{product.saleprice}</span>
              </span>
              <span className="text-decoration-line-through ms-1">MRP :₹{product.mrp}</span>
            </p>
          </div>

          <button
            className="btn ms-2 addbtnhover"
            style={{ flexShrink: 0, backgroundColor: theme.palette.indigo.lighter, color: theme.palette.indigo.main }}
            onClick={handleAddCart}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>

      {showPopup && (
        <ProductDetailsPopup index={index}  key={selectedProduct._id} product={selectedProduct} onClose={closePopup} />
      )}
    </>
  );
}
