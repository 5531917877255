import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    indigo: {
      light: '#e8eaf6', //50
      lighter:'#c5cae9', //100
      main: '#3949ab', //600
      dark: '#303f9f', //700
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

export default theme;