import React, { useEffect, useState } from "react";
import { checkOrder, fetchShopDetails, refreshAccessToken } from "../api/useFetchProducts";
import { useSelector } from "react-redux";
import theme from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faAngleLeft, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useDispatch } from "react-redux";
import { clearCart } from "../../redux/slices/CartSlice";
import OrderProdTemp from "./OrderProdTemp";

export default function PlaceOrder() {
  const [shopData, setShopData] = useState(null);
  const [error, setError] = useState(null);
  const cartitem = useSelector((state) => state.cartlists.cartItems);
  const totalPrice = cartitem.reduce((total, item) => {
    return total + item.saleprice * item.quantity;
  }, 0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const productIds = cartitem.map((item) => item._id);
  const [checkData, setCheckData] = useState([]);
  const [warning, setWarning] = useState();
  const role = localStorage.getItem("userRole");
  const selectedShop = localStorage.getItem('selectedShop');
  const shopDetail = selectedShop ? JSON.parse(selectedShop) : null;
  console.log("shopDetails",shopDetail)

  const fetchOrderData = async () => {
    try {
      const data = await checkOrder(productIds);
      setCheckData(data.results);
      console.log("Order data:", data.results);
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };

  useEffect(() => {
    fetchOrderData();
  }, []);
  console.log("chekdaasdas", checkData);
  // useEffect(() => {
  //   const data = Cookies.get("shopData");
  //   if (data) {
  //     setShopData(JSON.parse(data));
  //   } else {
  //     console.error("No shop data found in cookies.");
  //   }
  // }, []);
  useEffect(() => {
    const getShopDetails = async () => {
      let token = localStorage.getItem('accessToken');
      try {
        const data = await fetchShopDetails();
        
        if (data.statuscode === 200) {
          setShopData(data.results);
        } else if (data.statuscode === 401) {
          throw new Error("Unauthorized");
        }
        console.log("as", data);

      } catch (error) {
        if (error.message === "Unauthorized") {
          // Attempt to refresh the token if the response is 401
          token = await refreshAccessToken();
          if (token) {
            // Retry fetching products with the new token
            getShopDetails();
          } else {
            localStorage.removeItem("accessToken");
            localStorage.removeItem('userRole')
            localStorage.removeItem("listOrder");
            navigate("/login");
          }
        } else {
          console.error("Error fetching products:", error);
          setError("Failed to fetch products. Please try again later.");
          localStorage.removeItem("accessToken");
          localStorage.removeItem('userRole')
          localStorage.removeItem("listOrder");
          navigate("/login");
        }
        // setError(error.message);
      }
    };

    getShopDetails();
  }, []);

  const handlePlaceOrder = async (event) => {
    if (checkData.some((item) => item.status === "DELETED")) {
      setWarning(true);
    } else {
      event.preventDefault();
      // Format cart items to the specified structure
      const cartData = cartitem.map((item) => ({
        productid: item._id, // Ensure this key matches your product ID
        code: item.code || "", // Replace with actual code if available
        title: item.title,
        mrp: item.mrp,
        saleprice: item.saleprice,
        quantity: item.quantity.toString(), // Convert to string as required
      }));

      try {
        let response;
        if(role==="AGENT"){
         response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}add-user-order`,
          { shopid:shopDetail._id,
            cartData }, // Pass the structured cartData in the request body
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }else{
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}add-order`,
          { cartData }, // Pass the structured cartData in the request body
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
        if (
          response.data.statusCode === 201 &&
          response.data.code === "ORDER_CREATED" || response.data.code === 'CREATED'
        ) {
          dispatch(clearCart());
        }
        navigate("/orderHistory");
        console.log(
          "Order placed successfully:",
          response.data.statusCode,
          response.data.code
        );

        // Handle success, e.g., navigate to a success page or show a message
      } catch (error) {
        console.error("Order placement failed:", error);
        setError("Failed to place order. Please try again.");
      }
    }
  };

  return (
    <>
      {/* {shopData && ( */}
        <div className="container">
            <div className="d-flex justify-content-between py-3 ">
          <div className="fw-semibold fs-5">CheckOut</div>
          <div><button className="btn btn-sm" onClick={()=>navigate('/')} style={{backgroundColor:theme.palette.indigo.lighter}}><FontAwesomeIcon icon={faAngleLeft} className="me-2"/>Back</button></div>
          </div>
          <div className="row">
            {cartitem.map((item, index) => (
              <OrderProdTemp key={item._id} index={index} item={item} />
              //   <div key={item._id} className="col-md-12 mb-1">
              //     <div className="d-flex justify-content-between align-items-start p-3 mt-1  rounded-3"
              //     style={{backgroundColor:theme.palette.indigo.light}}>
              //       <div className="flex-grow-1">
              //         <span
              //           className="fw-semibold text-break d-block title"
              //           style={{
              //             fontSize: '15px',
              //             wordWrap: 'break-word',
              //             whiteSpace: 'normal',
              //           }}
              //         >
              //           {item.title}
              //         </span>
              //         <p className="mb-0 price" style={{ fontSize: '14px' }}>
              //           <span className="text-dark me-2 ">
              //           {item.quantity} × <span className="">₹{(item.saleprice).toFixed(2)}</span>
              //           </span>
              //           {/* <span className="ms-1">MRP :₹{item.mrp}</span> */}
              //         </p>
              //       </div>
              //      <div>
              //       <div
              //         className="ms-2 text-end"
              //         style={{ flexShrink: 0 ,fontSize:'14px'}}
              //       >
              //        Total
              //       </div>
              //       <div
              //         className="ms-2 fw-semibold"
              //         style={{ flexShrink: 0,fontSize:'15px' }}
              //       >
              //        {((item.quantity)*(item.saleprice)).toFixed(2)}
              //       </div>
              //       </div>
              //     </div>
              //   </div>
            ))}
            <div className="col-12">
            <div className="col-12">
  {role === 'AGENT' && selectedShop ? (
    <>
      {/* Address and Contact Details */}
      {/* <div className="row">
        <div className="col-md-12 col-sm-12 mb-4">
          <span className="fw-semibold" style={{ fontSize: '16px' }}>
            Address Details
          </span>
          <div
            className="d-flex justify-content-between"
            style={{ fontSize: '13px' }}
          >
            <div className="d-flex flex-column">
              <span className="fw-semibold">{shopDetail.name}</span>
            </div>
          </div>
          <div
            className="d-flex justify-content-between pt-1"
            style={{ fontSize: '13px' }}
          >
            <span className="text-truncate">
              {shopDetail.address.addressline1}
            </span>
          </div>
          <div
            className="d-flex justify-content-between"
            style={{ fontSize: '13px' }}
          >
            <span>
              {shopDetail?.address?.city}, {shopDetail?.address?.state} -{' '}
              {shopDetail?.address?.pincode}
            </span>
          </div>
          <div
            className="d-flex justify-content-between"
            style={{ fontSize: '13px' }}
          >
            <span>Ph: {shopDetail?.phone}</span>
          </div>
        </div>
      </div> */}

      {/* Billing Info */}
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <span className="fw-semibold" style={{ fontSize: '16px' }}>
            Billing Info
          </span>
          <div
            className="d-flex justify-content-between"
            style={{ fontSize: '13px' }}
          >
            <div className="d-flex flex-column">
              <span className="fw-semibold">{shopDetail?.name}</span>
              <span>
                GST:{' '}
                <span className="fw-semibold">{shopDetail?.gstnumber}</span>
              </span>
            </div>
          </div>
          <div
            className="d-flex justify-content-between pt-1"
            style={{ fontSize: '13px' }}
          >
            <span className="text-break">
              {shopDetail?.address?.addressline1},{' '}
              {/* {selectedShop?.billing_address?.addressline2} */}
            </span>
          </div>
          <div
            className="d-flex justify-content-between"
            style={{ fontSize: '13px' }}
          >
            <span>
              {shopDetail?.address?.city},{' '}
              {shopDetail?.address?.state} -{' '}
              {shopDetail?.address?.pincode}
            </span>
          </div>
          <div
            className="d-flex justify-content-between mb-2"
            style={{ fontSize: '13px' }}
          >
            <span>Ph: {shopDetail?.phone}</span>
          </div>
        </div>
      </div>
    </>
  ) : (
    shopData && (
      <>
        {/* Address and Contact Details */}
        <div className="row">
          <div className="col-md-12 col-sm-12 mb-4">
            <span className="fw-semibold" style={{ fontSize: '16px' }}>
              Address Details
            </span>
            <div
              className="d-flex justify-content-between"
              style={{ fontSize: '13px' }}
            >
              <div className="d-flex flex-column">
                <span className="fw-semibold">{shopData?.name}</span>
              </div>
            </div>
            <div
              className="d-flex justify-content-between pt-1"
              style={{ fontSize: '13px' }}
            >
              <span className="text-truncate">
                {shopData?.address?.addressline1}, {shopData?.address?.addressline2}
              </span>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ fontSize: '13px' }}
            >
              <span>
                {shopData?.address?.city}, {shopData?.address?.state} -{' '}
                {shopData?.address?.pincode}
              </span>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ fontSize: '13px' }}
            >
              <span>Ph: {shopData?.phone}</span>
            </div>
          </div>
        </div>

        {/* Billing Info */}
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <span className="fw-semibold" style={{ fontSize: '16px' }}>
              Billing Info
            </span>
            <div
              className="d-flex justify-content-between"
              style={{ fontSize: '13px' }}
            >
              <div className="d-flex flex-column">
                <span className="fw-semibold">{shopData?.name}</span>
                <span>
                  GST:{' '}
                  <span className="fw-semibold">{shopData?.gstnumber}</span>
                </span>
              </div>
            </div>
            <div
              className="d-flex justify-content-between pt-1"
              style={{ fontSize: '13px' }}
            >
              <span className="text-break">
                {shopData?.billing_address?.addressline1},{' '}
                {shopData?.billing_address?.addressline2}
              </span>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ fontSize: '13px' }}
            >
              <span>
                {shopData?.billing_address?.city},{' '}
                {shopData?.billing_address?.state} -{' '}
                {shopData?.billing_address?.pincode}
              </span>
            </div>
            <div
              className="d-flex justify-content-between mb-2"
              style={{ fontSize: '13px' }}
            >
              <span>Ph: {shopData?.phone}</span>
            </div>
          </div>
        </div>
      </>
    )
  )}
</div>

            </div>
            <div className="col-12 py-2">
              {cartitem.length > 0 && (
                <div
                  className="p-3"
                  style={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "10px",
                  }}
                >
                  <div className="fs-5 fw-bold mb-2 text-start ">Check Out</div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span
                      style={{
                        fontSize: "1rem",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      Total Amount
                    </span>
                    <span
                      className="roboto"
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "600",
                        color: theme.palette.indigo.dark,
                      }}
                    >
                      ₹{totalPrice.toFixed(2)}
                    </span>
                  </div>
                  <div className="py-1">
                    {warning && (
                      <div
                        className="alert alert-warning text-center mb-2"
                        role="alert"
                      >
                        Please remove unavailable products before PlaceOrder.
                      </div>
                    )}
                    <button
                      className="btn popaddbtnhover"
                      style={{
                        width: "100%",
                        backgroundColor: theme.palette.indigo.main,
                        color: "white",
                      }}
                      onClick={handlePlaceOrder}
                    >
                      PlaceOrder <FontAwesomeIcon icon={faArrowRightLong} />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      {/* )} */}
    </>
  );
}
