import React, { useEffect, useState } from "react";
import theme from "../../theme";
import { useSelector } from "react-redux";
import { checkOrder } from "../api/useFetchProducts";
import { useDispatch } from "react-redux";
import { removeItemFromCart } from "../../redux/slices/CartSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { checkUserOrder } from "../api/salesapi";

export default function OrderProdTemp({ item, index }) {
  const cartitem = useSelector((state) => state.cartlists.cartItems);
  const dispatch=useDispatch();
  const productIds = cartitem.map((item) => item._id);
  const [checkData, setCheckData] = useState([]);
  const [checkProduct, setCheckedProduct] = useState("");
  const [removingItemId, setRemovingItemId] = useState(null);

  const fetchOrderData = async () => {
    try {
      const data = await checkOrder(productIds);
      setCheckData(data.results);
      console.log("Order data:", data.results);
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };

  useEffect(() => {
    fetchOrderData();
  }, []);

  useEffect(() => {
    if (checkData.length > 0) {
      const foundProduct = checkData.find(
        (it) => it.productid === cartitem[index]._id
      );
      setCheckedProduct(foundProduct);
    }
  }, [checkData]);

  const handleRemoveItem = (id) => {
    setRemovingItemId(id); // Set the removing item ID for animation
    setTimeout(() => {
      dispatch(removeItemFromCart(id));
      setRemovingItemId(null);
    }, 300); // Delay to allow animation to complete before removal
  };

  return (
    <>
      {checkProduct && (
        <div key={item._id} className={`col-md-12 mb-2 ${
            removingItemId === item._id ? 'removing' : ''
          }`}>
          <div
            className="d-flex justify-content-between align-items-start p-3 mt-1  rounded-3 position-relative"
            style={{ backgroundColor: theme.palette.indigo.light }}
          >
            <button
          className="btn rounded-circle"
          style={{
            position: 'absolute',
            top: '-10px',
            left: '-10px',
            width: '25px',
            height: '25px',
            padding: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: "#e0e0e0",
            color: "#616161",
          }}
          onClick={() => handleRemoveItem(item._id)}
        >
          <FontAwesomeIcon style={{ fontSize: "12px" }} icon={faXmark} />
        </button>
            {checkProduct.status === "ACTIVE" ? (
              <div className="flex-grow-1">
                <span
                  className="fw-semibold text-break d-block title"
                  style={{
                    fontSize: "15px",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  {checkProduct.title}
                </span>
                <p className="mb-0 price" style={{ fontSize: "14px" }}>
                  <span className="text-dark me-2 ">
                    {item.quantity} ×{" "}
                    <span className="">
                      ₹{checkProduct.saleprice.toFixed(2)}
                    </span>
                  </span>
                  {/* <span className="ms-1">MRP :₹{item.mrp}</span> */}
                </p>
              </div>
            ) : (
              <div className="flex-grow-1">
                <span
                  className="fw-semibold text-break d-block title"
                  style={{
                    fontSize: "15px",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  {item.title}
                </span>
                <p className="mb-0 price" style={{ fontSize: "14px" }}>
                  <span className="text-dark me-2 ">
                    {item.quantity} ×{" "}
                    <span className="">₹{item.saleprice.toFixed(2)}</span>
                  </span>
                  {/* <span className="ms-1">MRP :₹{item.mrp}</span> */}
                </p>
              </div>
            )}
            {checkProduct.status === "DELETED" && (
              <div className="badge bg-danger">UnAvailable</div>
            )}
            <div>
              <div
                className="ms-2 text-end"
                style={{ flexShrink: 0, fontSize: "14px" }}
              >
                Total
              </div>
              {checkProduct.status === "ACTIVE" ? (
                <div
                  className="ms-2 fw-semibold"
                  style={{ flexShrink: 0, fontSize: "15px" }}
                >
                  {(item.quantity * checkProduct.saleprice).toFixed(2)}
                </div>
              ) : (
                <div
                  className="ms-2 fw-semibold"
                  style={{ flexShrink: 0, fontSize: "15px" }}
                >
                  {(item.quantity * item.saleprice).toFixed(2)}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
