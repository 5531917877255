import { faMinus, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeItemFromCart,incrementQuantity,decrementQuantity,setCartlist } from "../../../redux/slices/CartSlice";
import theme from "../../../theme";
import { checkOrder } from "../../api/useFetchProducts";
// import { checkOrder } from "../../api/useFetchProducts";

export default function CartTemp({ it,index }) {
  const cartitem=useSelector((state)=>state.cartlists.cartItems)
  const [quantities, setQuantities] = useState( cartitem.map((it) => it.quantity || 1));
  const dispatch = useDispatch();
  const [removingItemId, setRemovingItemId] = useState(null);
  const productIds = cartitem.map(item => item._id);
  const [checkData,setCheckData]=useState([])
  const [checkProduct,setCheckedProduct]=useState('');

  const fetchOrderData = async () => {
    try {
        const data = await checkOrder(productIds);
        setCheckData(data.results)
        console.log("Order data:", data.results);
    } catch (error) {
        console.error("Error fetching order data:", error);
    }
};

useEffect(() => {
    fetchOrderData();
}, []);

useEffect(() => {
  if (checkData.length > 0) {
    const foundProduct = checkData.find((it) => it.productid === cartitem[index]._id);
    setCheckedProduct(foundProduct);
  }
}, [checkData]);

  useEffect(() => {
    setQuantities(cartitem.map((it) => it.quantity || 1));
  }, [cartitem]);

  const handleQuantityChange = (index,value) => {
    if (value === '') {
      const newQuantities = [...quantities];
      newQuantities[index] = ''; // Temporarily set it to an empty string
      setQuantities(newQuantities);
      return;
    }
  
    const parsedValue = parseInt(value, 10);
    const newValue = isNaN(parsedValue) || parsedValue < 1 ? 1 : parsedValue;
  
    const newQuantities = [...quantities];
    newQuantities[index] = newValue; 
    setQuantities(newQuantities); 
   
    dispatch(setCartlist(cartitem.map((it, idx) => (idx === index ? { ...it, quantity: newValue } : it))));
  };

  // Function to handle quantity increase
  const increaseQuantity = (index) => {
    dispatch(incrementQuantity(cartitem[index]._id))
  };

  // Function to handle quantity decrease
  const decreaseQuantity = (index) => {
    if (quantities[index] > 1) {
      dispatch(decrementQuantity(cartitem[index]._id));
    }
    else {
      handleRemoveItem(cartitem[index]._id); // Remove the item if quantity is 1 and user tries to decrease it
    }
  };

  const handleRemoveItem = (id) => {
    setRemovingItemId(id); // Set the removing item ID for animation
    setTimeout(() => {
      dispatch(removeItemFromCart(id));
      // fetchOrderData();
      setRemovingItemId(null);
    }, 300); 
  };
console.log('it',it)
  return (
    <>
      <li
        className={`list-group-item d-flex flex-column my-1 ${
          removingItemId === it._id ? 'removing' : ''
        }`}
        style={{ border: "none",borderBottom:'1px solid #bdbdbd' }}
        key={it.id}
      >
        {/* Product Details */}
      <span className="fw-semibold title" style={{ fontSize: "16px" }}>
        {it.title}
      </span>
        <div className="d-flex justify-content-between align-items-center mt-1 pb-2">
          <span
            className="roboto"
            style={{ fontSize: "13px", color: "#9e9e9e" }}
          >
            <span>
              {it.quantity} × ₹{(it.saleprice).toFixed(2)}
            </span>
          </span>
          <span className="fw-semibold roboto" style={{ fontSize: "16px" }}>
            ₹{(it.quantity * it.saleprice).toFixed(2)}
          </span>
        </div>

        <div className="d-flex justify-content-between align-items-center ">
          <button
            className="btn btn-sm cancelbtnhove"
            style={{ backgroundColor: theme.palette.indigo.light, color: "#616161",borderRadius:'8px' }}
            onClick={() => handleRemoveItem(it._id)}
          >
            <FontAwesomeIcon icon={faXmark} className="" />
            {/* Remove */}
          </button>
          {checkProduct && checkProduct.status==="DELETED" &&(
          <div className="badge bg-danger">
                UnAvailable
          </div>
          )}
          <div className="d-flex align-items-center justify-content-between">
            <button
              className="btn btn-sm btn-outline-secondary rounded-3 p-0"
              onClick={() => decreaseQuantity(index)}
              style={{ width: "25px", height: "25px" }}
            >
              <FontAwesomeIcon icon={faMinus} />
            </button>

            <input
              type="number"
              className="text-center fw-semibold mx-1"
              value={quantities[index]}
              onChange={(e) => handleQuantityChange(index, e.target.value)}
              onBlur={() => {
                if (quantities[index] === '') {
                  handleQuantityChange(index, '1'); // Reset to 1 if the field is empty when focus is lost
                }
              }}
              style={{
                width: "50px",
                height: "25px",
                lineHeight: "25px",
                border: "none",
                backgroundColor: "#f5f5f5",
                borderRadius: "4px",
                textAlign: "center",
              }}
            />

            <button
              className="btn btn-sm btn-outline-secondary rounded-3 p-0"
              onClick={()=>increaseQuantity(index)}
              style={{ width: "25px", height: "25px" }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>
      </li>
    </>
  );
}
