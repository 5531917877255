import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./Components/home/home";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faBars,
  faChevronDown,
  faHistory,
  faShop,
} from "@fortawesome/free-solid-svg-icons";
import Login from "./Components/login/Login";
import { useNavigate } from "react-router-dom";
import { fetchShopDetails } from "./Components/api/useFetchProducts";
import UserProfile from "./Components/profile/UserProfile";
import PlaceOrder from "./Components/checkOut/PlaceOrder";
import { Avatar } from "@mui/material";
import OrderHistory from "./Components/orderHistory/OrderHistory";
import OrderStatus from "./Components/orderHistory/OrderStatus";
import SalesLogin from "./Components/login/SalesLogin";
import ShopList from "./Components/shopList/ShopList";
import theme from "./theme";

function Header() {
  const location = useLocation();
  const [shopData, setShopData] = useState(null);
  const [error, setError] = useState(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const role = localStorage.getItem("userRole");
  const selectedShop = localStorage.getItem('selectedShop');
  const shopDetail = selectedShop ? JSON.parse(selectedShop) : null;

  const navigate = useNavigate();
  useEffect(() => {
    const getShopDetails = async () => {
      try {
        const data = await fetchShopDetails();
        setShopData(data.results);
        console.log("as", data);
      } catch (error) {
        setError(error.message);
      }
    };

    getShopDetails();
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        // If scrolling down, hide the header
        setIsHeaderVisible(false);
      } else {
        // If scrolling up, show the header
        setIsHeaderVisible(true);
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const handleLogOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem('userRole')
    localStorage.removeItem("listOrder");
    navigate("/login");
  };

  if (location.pathname === "/login" || location.pathname === '/salesLogin') {
    return null;
  }

  return (
    <nav className={`nav-bar-bg myHeader `}>
      <div className="d-flex align-items-center style">
        <div className="container-div d-flex container-fluid justify-content-between">
          {/* Menu Icon */}
          <Nav className="nav1" title="Menu">
            <a
              className="text-white header-menu-padding text-center"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasMenu"
              aria-controls="offcanvasMenu"
              aria-label="Toggle navigation"
            >
              <FontAwesomeIcon
                className="fs-4"
                icon={faBars}
                style={{ verticalAlign: "-webkit-baseline-middle" }}
              />
            </a>
          </Nav>
          <Nav onClick={()=>navigate('/')} className="pointer mx-auto pe-4">
                <img
                  style={{width:'3.5rem'}}
                  src="/alagappa_logo.png"
                  alt="Noava Logo"
                />
            </Nav>
           
        </div>

        {/* Offcanvas for Menu (left side) */}
       
          <div
            className="offcanvas offcanvas-start"
            data-bs-scroll="true"
            data-bs-backdrop="true" // Enable backdrop
            tabIndex="-1"
            id="offcanvasMenu"
            aria-labelledby="offcanvasMenuLabel"
            style={{
              maxHeight: "100%",
              overflowY: "auto",
            }}
          >
            
            <div className="side-nav-header-width sticky-top pointer">
              {/* <button
              type="button"
              className="btn btn-close btn-close-white p-2 close-btn"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button> */}
            {role==='AGENT' ? <div
                className="text-white text-start  "
                onClick={() => navigate("/profile")}
                data-bs-dismiss="offcanvas"
              >
                {/* <img src="/pharmacy.png" style={{ width: "30px"}} />{" "}
              <span className="text-white fw-semibold ps-2">{shopData.name}</span> */}
                <li className="d-flex align-items-center justify-content-between">
                  <Avatar
                    alt="Stejo"
                    sx={{ width: 45, height: 45 }}
                    style={{ cursor: "pointer" }}
                  />
                  <div className="ms-2 flex-grow-1">
                  {shopDetail && (
                    <div className="d-flex flex-column">
                      <span
                        className="text-truncate fw-semibold"
                        style={{
                          maxWidth: "150px",
                          display: "block",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {shopDetail.name}
                      </span>
                      <span
                        className="text-truncate text-white"
                        style={{
                          fontSize: "11px",
                          maxWidth: "150px",
                          display: "block",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {shopDetail.shopcode}
                      </span>
                    </div>
                    )}
                  </div>
                  <div className="">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </li>
              </div>:
              <div
                className="text-white text-start  "
                onClick={() => navigate("/profile")}
                data-bs-dismiss="offcanvas"
              >
                {/* <img src="/pharmacy.png" style={{ width: "30px"}} />{" "}
              <span className="text-white fw-semibold ps-2">{shopData.name}</span> */}
                <li className="d-flex align-items-center justify-content-between">
                  <Avatar
                    alt="Stejo"
                    sx={{ width: 45, height: 45 }}
                    style={{ cursor: "pointer" }}
                  />
                  <div className="ms-2 flex-grow-1">
                  {shopData && (
                    <div className="d-flex flex-column">
                      <span
                        className="text-truncate fw-semibold"
                        style={{
                          maxWidth: "150px",
                          display: "block",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {shopData.name}
                      </span>
                      <span
                        className="text-truncate text-white"
                        style={{
                          fontSize: "11px",
                          maxWidth: "150px",
                          display: "block",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {shopData.shopcode}
                      </span>
                    </div>
                    )}
                  </div>
                  <div className="">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </li>
              </div>}
              {/* <div className="text-white">{shopData.shopcode}</div> */}
            </div> 
            <div className="side-body">
              <ul className="list-group">
                <li
                  className="d-flex align-items-center mt-2 p-3 lihover pointer"
                  onClick={() => navigate("/")}
                  data-bs-dismiss="offcanvas"
                >
                  <span>
                    <FontAwesomeIcon icon={faShop} className="ps-2 pe-4" />
                  </span>
                  <span>Shop</span>
                </li>
                <li
                  className="d-flex align-items-center mt-2 p-3  lihover pointer"
                  data-bs-dismiss="offcanvas"
                  onClick={()=>navigate('/orderHistory')}
                >
                  <span>
                    <FontAwesomeIcon icon={faHistory} className="ps-2 pe-4" />
                  </span>
                  <span>Order history</span>
                </li>
                <li
                  className="d-flex align-items-center mt-2 p-3 lihover pointer"
                  onClick={handleLogOut}
                  data-bs-dismiss="offcanvas"
                >
                  <span>
                    <FontAwesomeIcon
                      icon={faArrowRightFromBracket}
                      className="ps-2 pe-4"
                    />
                  </span>
                  <span>LogOut</span>
                </li>
              </ul>
            </div>
            <footer>
            {role==="AGENT" ?
            <div className="side-nav-header-width sticky-bottom pointer">
              <div
                className="text-white text-start  "
                onClick={() => navigate("/profile")}
                data-bs-dismiss="offcanvas"
              >
                <li className="d-flex align-items-center justify-content-between">
                  <Avatar
                    alt="Stejo"
                    src="/creativelogo.jpeg"
                    sx={{ width: 45, height: 45 }}
                    style={{ cursor: "pointer" }}
                  />
                  <div className="ms-2 flex-grow-1">
                  {/* {shopData && ( */}
                    <div className="d-flex flex-column">
                      <span
                        className="text-truncate fw-semibold"
                        style={{
                          maxWidth: "150px",
                          display: "block",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {/* {shopData.name} */}
                        AGENT
                      </span>
                      <span
                        className="text-truncate text-white"
                        style={{
                          fontSize: "11px",
                          maxWidth: "150px",
                          display: "block",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {/* {shopData.shopcode} */}
                        AGENTCode
                      </span>
                    </div>
                    {/* )} */}
                  </div>
                  <div className="">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </li>
              </div>
            </div> 
            :''}
            </footer>
          </div>
      </div>
    </nav>
  );
}

function App() {
  return (
    <BrowserRouter>
      {/* Header is conditionally rendered here */}
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/placeOrder" element={<PlaceOrder />} />
        <Route path="/orderHistory" element={<OrderHistory/>} />
        <Route path="/orderStatus/:orderId" element={<OrderStatus/>} />
        <Route path="/salesLogin" element={<SalesLogin/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
