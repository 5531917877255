import { createSlice } from '@reduxjs/toolkit';

// Helper function to save the cart to local storage
const saveCartToLocalStorage = (cart) => {
  localStorage.setItem('cart', JSON.stringify(cart));
};

// Load cart from local storage if available
const loadCartFromLocalStorage = () => {
  try {
    const storedCart = localStorage.getItem('cart');
    return storedCart ? JSON.parse(storedCart) : [];
  } catch (error) {
    console.error('Failed to load cart from local storage:', error);
    return [];
  }
};

const initialState = {
  cartItems: loadCartFromLocalStorage(), // Load initial cart items from local storage
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItemToCart: (state, action) => {
      const item = state.cartItems.find((i) => i._id === action.payload._id);
      if (item) {
        // If the item exists, do nothing (or optionally increase quantity)
        item.quantity += 1; // Adjust if you want incremental addition here
      } else {
        // Add a new item with the specified or default quantity
        state.cartItems.push({ ...action.payload, quantity: action.payload.quantity || 1 });
      }
      saveCartToLocalStorage(state.cartItems);
    },
    
    removeItemFromCart: (state, action) => {
      state.cartItems = state.cartItems.filter((i) => i._id !== action.payload);
      saveCartToLocalStorage(state.cartItems); // Save updated cart to local storage
    },
    incrementQuantity: (state, action) => {
      const item = state.cartItems.find((i) => i._id === action.payload);
      if (item) {
        item.quantity += 1;
        saveCartToLocalStorage(state.cartItems); // Save updated cart to local storage
      }
    },
    decrementQuantity: (state, action) => {
      const item = state.cartItems.find((i) => i._id === action.payload);
      if (item && item.quantity > 1) {
        item.quantity -= 1;
        saveCartToLocalStorage(state.cartItems); // Save updated cart to local storage
      }
    },
    setCartlist: (state, action) => {
      state.cartItems = action.payload;
      saveCartToLocalStorage(state.cartItems); // Save updated cart to local storage
    },
    clearCart: (state) => {
      state.cartItems = [];
      saveCartToLocalStorage(state.cartItems); // Save updated cart to local storage
    },
  },
});

export const {
  addItemToCart,
  removeItemFromCart,
  incrementQuantity,
  decrementQuantity,
  setCartlist,
  clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;
