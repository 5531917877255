import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import React, { useState } from 'react';

export default function ProductDropdown({ shopList }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [showShopList, setShowShopList] = useState(false);
  const [open, setOpen] = useState(false);

  // Filter shops based on the search term
  const filteredShops = shopList.filter((shop) =>
    shop.name.toLowerCase().includes(searchTerm.toLowerCase())|| 
    String(shop.shopcode).toLowerCase().includes(searchTerm.toLowerCase())
  );
  // Handler for selecting a shop
  const handleSelectShop = (shop) => {
    // Store the selected shop's details in localStorage
    localStorage.setItem('selectedShop', JSON.stringify(shop));
    setSearchTerm(shop.name);
    setOpen(true);
    setShowShopList(false);  // Close the popup when a shop is selected
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
 console.log("selectedShop",localStorage.getItem('selectedShop'))
  // Show the Shop List popup
  const handleShowShopList = () => {
    setShowShopList(true);
  };

  // Close the Shop List popup
  const handleCloseShopList = () => {
    setShowShopList(false);
  };

  return (
    <div>
      <button className="btn btn-sm btn-primary" onClick={handleShowShopList}>
        Change Shop
      </button>

      {/* Shop List Popup */}
      {showShopList && (
        <div
          className="shop-list-popup"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={handleCloseShopList}
        >
          <div
            className="shop-list-container"
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              maxWidth: '400px',
              width: '95%',
              minHeight: '350px',
            }}
            onClick={(e) => e.stopPropagation()} // Prevent click from closing when inside the modal
          >
            <div className='text-start pb-2 fs-5'>Shop List</div>

            {/* Search Box */}
            <div className="d-flex pb-2" style={{ maxWidth: '100%', position: 'relative' }}>
              <input
                type="search"
                className=" search-input"
                placeholder="Search Shop"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  width: '100%',
                  padding: '8px',
                  borderRadius: '8px 0 0 8px', // Rounded only on the left side
                  border: 'none',
                  background: '#e0e0e0',
                }}
              />
              <span
                className="input-group-text"
                style={{
                  background: '#bdbdbd',
                  border: 'none',
                  borderRadius: '0 8px 8px 0', // Rounded only on the right side
                  display: 'flex',
                  alignItems: 'center', // Centers icon vertically
                  padding: '8px 12px', // Same padding as input
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon icon={faSearch} style={{ fontSize: '1rem', color: '#757575' }} />
              </span>
            </div>

            {/* Dropdown for Filtered Products with max height and scroll */}
            <div
              style={{
                width: '100%',
                padding: '8px',
                borderRadius: '4px',
                maxHeight: '200px', // Set maximum height for the dropdown
                overflowY: 'auto', // Enable vertical scrollin
              }}
            >
              {filteredShops.length > 0 ? (
                filteredShops.map((shop) => (
                  <div
                    className="text-start p-2 lihovers"
                    key={shop.shopcode}
                    onClick={() => handleSelectShop(shop)} // Pass the entire shop object
                    style={{ cursor: 'pointer' }}
                  >
                    {shop.name}({shop.shopcode})
                  </div>
                ))
              ) : (
                <div className="text-muted">No shops found</div>
              )}
            </div>

            {/* Close Button */}
            <button
              onClick={handleCloseShopList}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'transparent',
                border: 'none',
                fontSize: '20px',
                cursor: 'pointer',
              }}
            >
              &times; {/* Close icon */}
            </button>
          </div>
        </div>
      )}
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Shop Selected Successfully...
        </Alert>
      </Snackbar>
    </div>
  );
}
