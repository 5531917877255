import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchOrderData, refreshAccessToken } from "../api/useFetchProducts";
import { format } from "date-fns";
import theme from "../../theme";

export default function OrderStatus() {
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState(null);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();

  useEffect(() => {
    const getOrderData = async () => {
      try {
        const data = await fetchOrderData(orderId, token);
        if(data.statusCode===200){
        setOrderData(data.results); // Set the fetched data
        }else if (data.statusCode === 401) {
          throw new Error("Unauthorized");
        }
      } catch (err) {
        if (error.message === "Unauthorized") {
          // Attempt to refresh the token if the response is 401
          token = await refreshAccessToken();
          if (token) {
            // Retry fetching products with the new token
            getOrderData();
          } else {
            localStorage.removeItem("accessToken");
            localStorage.removeItem('userRole')
            localStorage.removeItem("listOrder");
            navigate("/login");
          }
        } else {
          console.error("Error fetching products:", error);
          setError("Failed to fetch products. Please try again later.");
          localStorage.removeItem("accessToken");
          localStorage.removeItem('userRole')
          localStorage.removeItem("listOrder");
          navigate("/login");
        }
      }
    };

    getOrderData();
  }, [orderId, token]);

  console.log("orderstatus", orderData);

  return (
    <div className="container">
      <h2 className="py-3">Order Details</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      {orderData ? (
        <div>
          <div className="d-flex justify-content-between">
            <div style={{ fontSize: "12px" }}>
              Order ID: <span className="fw-semibold">{orderData._id}</span>
            </div>
            <div style={{ fontSize: "12px" }}>
              Date:{" "}
              <span className="fw-semibold">
                {format(new Date(orderData.orderdate), "MMM/dd/yy")}
              </span>
            </div>
          </div>
          <div
            className="d-flex justify-content-between mb-3"
            style={{ fontSize: "12px" }}
          >
            <div>
              Status:
              <span
                className="fw-semibold"
                style={{
                  color:
                    orderData.status === "ACCEPTED"
                      ? "#4caf50"
                      : orderData.status === "CREATED"
                      ? "#fbc02d"
                      : orderData.status === "COMPLETED"
                      ? "#1976d2"
                      : "#e57373",
                }}
              >
                {" "}
                {orderData.status === "ACCEPTED"
                  ? "Accepted"
                  : orderData.status === "CREATED"
                  ? "Placed"
                  : orderData.status === "COMPLETED"
                  ? "Deliverd"
                  : "Canceled"}
              </span>
            </div>
            <div>
              Total:{" "}
              <span
                className="fw-semibold roboto"
                style={{ color: theme.palette.indigo.main }}
              >
                ₹{orderData?.grandtotal}
              </span>
            </div>
          </div>

          <div className="mb-4">
            <h5>Shipping Address</h5>
            <div style={{ fontSize: "14px" }}>
              <strong>{orderData?.shopid?.name}</strong>
              <div>{orderData?.shopid?.address?.addressline1}</div>
              <div>{orderData?.shopid?.address?.addressline2}</div>
              <div>
                {orderData?.shopid?.address?.city},{" "}
                {orderData?.shopid?.address?.state} -{" "}
                {orderData?.shopid?.address?.pincode}
              </div>
              <div>Phone: {orderData?.shopid?.phone}</div>
            </div>
          </div>

          <div className="mb-4">
            <h5>Billing Info</h5>
            <div style={{ fontSize: "14px" }}>
              <strong>{orderData?.shopid?.name}</strong>
              <div>GST No: {orderData?.shopid?.gstnumber}</div>
              <div>{orderData?.shopid?.billing_address?.addressline1}</div>
              <div>{orderData?.shopid?.billing_address?.addressline2}</div>
              <div>
                {orderData?.shopid?.billing_address?.city},{" "}
                {orderData?.shopid?.billing_address?.state} -{" "}
                {orderData?.shopid?.billing_address?.pincode}
              </div>
              <div>Phone: {orderData?.shopid?.phone}</div>
            </div>
          </div>

          <h5 className="mb-3">Order Items</h5>
          {orderData.cart_data.map((order) => (
            <div
              key={order.productid}
              className="p-3 mb-3 rounded-3"
              style={{
                backgroundColor: theme.palette.indigo.light,
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="d-flex justify-content-between mb-2">
                <div className="fw-semibold" style={{ fontSize: "16px" }}>
                  {order.title || "No title available"}
                </div>
              </div>
              <div
                className="d-flex justify-content-between"
                style={{ fontSize: "13px" }}
              >
                <div>
                  Selling Rate (Including Tax):{" "}
                  <span
                    className="roboto"
                    style={{ color: theme.palette.indigo.dark }}
                  >
                    ₹{order.saleprice}
                  </span>
                </div>
                <div>
                  Qty :<span className="fw-semibold">{order.quantity}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="py-3">Loading order data...</div>
      )}
    </div>
  );
}
